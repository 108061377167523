import React, { Component } from 'react';

class Terms extends Component {
  render() {
    return (
      <div>
        <h1 className="title">Terms of Service</h1>
        <h2>Is the App Free?</h2>
          <p>Currently the app is free and will remain that was for the forseable future.</p>
        <h2>What about my data?</h2>
          <p>You are responsible for your own mobile data limits when using the app, ie:  clicking on advertising items will use data.</p>
      </div>
    )
  }
}

export default Terms;