import React, { Component } from 'react';

class HomePage extends Component {
  render() {
    return (
      <div class="home">
        <h1 class="title">SpendTracker</h1>
        <div class="container">
          <div class="center">
            <a href='https://play.google.com/store/apps/details?id=com.spendtracker'><img class="google" alt='Get it on Google Play' src='google.png'/></a>
            <a href='https://apps.apple.com/app/id1566779043'><img class="apple" alt='Get it on IOS' src='apple.png'/></a>
          </div>
        </div>  
      </div>
    )
  }
}

export default HomePage;