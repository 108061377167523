import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavBar extends Component {
  render() {
    return (
      <header>
        <ul class="container">
          <li className="navButton"><Link  className="link" to="/">Home</Link></li>
          <li className="navButton"><Link className="link" to="/about">About</Link></li>
          <li className="navButton"><Link className="link" to="/terms">Terms of Service</Link></li>
          <li className="navButton"><Link className="link" to="/privacy">Privacy Policy</Link></li>
          <li className="navButton"><Link className="link" to="/contact">Contact</Link></li>
        </ul>
      </header>
    )
  }
}

export default NavBar;