import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div class="home">
       <h1 class="title">About</h1>
        <h2>Who are we?</h2>
        <p>Jadder Development House, is an Australian born business</p>
        <p>We make mobile applications across a range of fields from Finance to Sports</p>
      </div>
    )
  }
}

export default About;