import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import HomePage from './components/pages/homePage';
import Privacy from './components/pages/privacy';
import Contact from './components/pages/contact';
import About from './components/pages/about';
import Terms from './components/pages/terms';
import NavBar from './components/headerComponent/navBar';
import Footer from './components/footerComponent/footer';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <NavBar />  
            <Route name="home" exact path="/" component={HomePage} />
            <Route name="privacy" exact path="/privacy" component={Privacy} />
            <Route name="terms" exact path="/terms" component={Terms} />
            <Route name="about" exact path="/about" component={About} />
            <Route name="contact" exact path="/contact" component={Contact} />
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App;